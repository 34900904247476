<template>
  <div>
    <div v-if="signedIn">
      <div class="mobile-header">
        <router-link to="/" v-on:click.native="display_subheader = false" class="mobile-header-tab">
          <font-awesome-icon style="font-size: 22px" :icon="['fas', 'home']" />
          <span>Home</span>
        </router-link>

        <router-link v-on:click.native="display_subheader = false" to="/schedule" class="mobile-header-tab">
          <font-awesome-icon style="font-size: 22px" :icon="['fas', 'calendar-alt']" />
          <span>Schedule</span>
        </router-link>

        <router-link v-on:click.native="display_subheader = false" to="/search" class="mobile-header-tab">
          <font-awesome-icon style="font-size: 22px" :icon="['fas', 'search']" />
          <span>Search</span>
        </router-link>

        <router-link v-on:click.native="display_subheader = false" to="/profile" class="mobile-header-tab">
          <font-awesome-icon style="font-size: 22px" :icon="['fas', 'user-alt']" />
          <span>My Profile</span>
        </router-link>

        <div @click="display_subheader = !display_subheader" :class="{ 'mobile-header-tab': true}">
          <font-awesome-icon style="font-size: 22px" :icon="['fas', 'ellipsis-h']" />
          <span>More</span>
        </div>
      </div>

      <!-- Sub header -->
      <div :class="{'mobile-subheader': true, displayed: display_subheader}">
        <router-link v-on:click.native="display_subheader = false" to="/faq" class="mobile-subheader-tab">
          <span>FAQ</span>
        </router-link>

        <router-link v-on:click.native="display_subheader = false" to="/crp" class="mobile-subheader-tab">
          <span>Verify carpooler</span>
        </router-link>

        <router-link v-if="false" v-on:click.native="display_subheader = false" to="/organization" class="mobile-subheader-tab">
          <span>My company</span>
        </router-link>

        <router-link v-on:click.native="display_subheader = false" to="/settings" class="mobile-subheader-tab">
          <span>Settings</span>
        </router-link>

        <div @click="logout" class="mobile-subheader-tab">
          <span>Logout</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      display_subheader: false
    };
  },

  computed: {
    
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(result => {
        this.display_subheader = false;
        this.$router.push('/login').catch(()=>{});
      });
    }
  },

  computed: {
    signedIn() {
      return this.$store.getters.signedIn;
    }
  }
};
</script>