import Vue from 'vue'

import 'pwacompat'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/custom.scss'
import '@/assets/styles/animations.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import mixin_methods from '@/utils/mixin_methods'

import Card from '@/components/Card'
import listeners from '@/firebase/listeners'
import firebase from '@/firebase'
import store from './store'
import '@/misc/register-service-worker';
import router from './router'
import App from './App.vue'

import InnerPageTitle from '@/components/Base/InnerPageTitle';

Vue.component('inner-page-title', InnerPageTitle);

Vue.use(Card)


Vue.config.productionTip = false
library.add(fas)
library.add(far)
library.add(fab)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ElementUI)

Vue.prototype.$firebase = firebase;
Vue.prototype.$analytics = firebase.analytics();
Vue.prototype.$SharoAPI = firebase.functions('europe-west2').httpsCallable('SharoAPI');
Vue.mixin({
  methods: {
    ...mixin_methods,
    SharoAPI: async (_obj, auth_handler = false) => {
      let result = await firebase.functions('europe-west2').httpsCallable('SharoAPI')(_obj);

      if (auth_handler) {
        // If error is AUTH_PERMISSION_DENIED
        if (result.data.error && result.data.code === "AUTH_PERMISSION_DENIED") {
          let verifications = [];
          
          if (result.data.data.includes('EMAIL_NOT_VERIFIED')) {
            verifications.push('EMAIL');
          } 
          
          if (result.data.data.includes('PHONE_NOT_VERIFIED')) {
            verifications.push('PHONE');
          } 
          
          if (result.data.data.includes('PHOTO_NOT_UPLOADED')) {
            verifications.push('PHOTO');
          }

          verifications = verifications.join(',');

          return { ...result, redirect: true, redirect_name: 'profile_verification', redirect_verifications: verifications };
        }
      }

      return result;
    },
  }
})

new Vue({
  router,
  store,
  firebase,
  created: () => {
    // Create deviceID if not exists. "{date(YYYY-MM-DD)}_{UID}"
    let deviceID = localStorage.getItem('deviceID')
    if (!deviceID) {
      localStorage.setItem('deviceID', `${mixin_methods.getFullDate()}_${mixin_methods.generateShortID()}`);
      deviceID = localStorage.getItem('deviceID');
    }

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.analytics().setUserId(user.uid);
        store.dispatch('checkUserClaims', true);
        listeners.user();
        listeners.organization();
        // listeners.wallet();
        listeners.company();
        listeners.userSchedule();
        listeners.userHappiness();
        listeners.userFavorites();
        listeners.adminInfo();
        store.dispatch('getCarpoolingFriends');
        store.dispatch('getIPInformation');


        // Set user properties for analytics
        user.getIdTokenResult(true).then(r => {
          firebase.analytics().setUserProperties({
            organization: r.claims.organization,
            company: r.claims.company
          });
        });


        // Update user auth with email_verified on Firestore
        store.dispatch('updateProfileAuth');
      } else {
        firebase.analytics().setUserId(null);
        listeners.stop_listeners()
      }
    })
  },
  render: h => h(App)
}).$mount('#app')
